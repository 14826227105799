import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  HStack,
  Image,
  Show,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  VisuallyHidden
} from '@chakra-ui/react';
import * as React from 'react';
import { FC } from 'react';
import NextLink from 'next/link';
import { NavContent } from './navBar/NavContent';
import { ToggleButton } from './ToggleButton';

interface NavbarProps {
  darkBackground?: boolean;
}

export const Navbar: FC<NavbarProps> = (props) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <Box ps={{ base: 2, md: '8' }} pe={{ base: 2, md: '0' }} bg='transparent'>
      {/* Desktop */}
      <Show above='md'>
        <Flex as='nav' aria-label='SmartParse navigation' align='center' justify='space-between' height='100%'>
          <NextLink href='/' passHref rel='home'>
            <Box m={4}>
              <HStack spacing={0}>
                <Image src='/images/logo.webp' alt='SmartParse Logo' w='60px' height='60px' mr={4} />
                <Text fontWeight='bold' fontSize='2xl' color={useColorModeValue('brand.400', 'whiteAlpha.500')}>
                  Smart
                </Text>
                <Text fontWeight='bold' fontSize='2xl' color={useColorModeValue('brand.500', 'whiteAlpha.700')}>
                  Parse
                </Text>
              </HStack>
              <VisuallyHidden>SmartParse</VisuallyHidden>
            </Box>
          </NextLink>
          <NavContent.Desktop {...props} display={{ base: 'none', md: 'flex' }} />
        </Flex>
      </Show>

      {/* Mobile */}
      <Show below='md'>
        <Box as='section'>
          <Box position='relative'>
            <Container py='4'>
              <HStack justify='space-between'>
                <Box as={NextLink} href='/' rel='home'>
                  <HStack spacing={0}>
                    <Image src='/images/logo.webp' alt='SmartParse Logo' w='60px' height='60px' mr={4} />
                    <Text fontWeight='bold' fontSize='2xl' color={useColorModeValue('brand.400', 'whiteAlpha.500')}>
                      Smart
                    </Text>
                    <Text fontWeight='bold' fontSize='2xl' color={useColorModeValue('brand.500', 'whiteAlpha.700')}>
                      Parse
                    </Text>
                  </HStack>
                  <VisuallyHidden>SmartParse</VisuallyHidden>
                </Box>
                <ToggleButton onClick={onToggle} isOpen={isOpen} aria-label='Open Menu' />
                <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
                  <DrawerContent bg='bg-default'>
                    <DrawerBody mx={2}>
                      <Stack spacing='6' align='stretch'>
                        <HStack spacing={0}>
                          <Image src='/images/logo.webp' alt='SmartParse Logo' w='60px' height='60px' mr={4} />
                          <Text
                            fontWeight='bold'
                            fontSize='2xl'
                            color={useColorModeValue('brand.400', 'whiteAlpha.500')}
                          >
                            Smart
                          </Text>
                          <Text
                            fontWeight='bold'
                            fontSize='2xl'
                            color={useColorModeValue('brand.500', 'whiteAlpha.700')}
                          >
                            Parse
                          </Text>
                        </HStack>
                        <VisuallyHidden>SmartParse</VisuallyHidden>
                        <NavContent.Mobile />
                      </Stack>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </HStack>
            </Container>
          </Box>
        </Box>
      </Show>
    </Box>
  );
};
