import React from 'react';
import { Box, BoxProps, Button, ButtonGroup, ButtonProps, Flex, HStack, Spacer } from '@chakra-ui/react';
import NextLink from 'next/link';
import { DarkModeSwitch } from '../../DarkModeSwitch';
import { useAuthState } from '../../../contexts/AuthContext';

export interface LinkType {
  id?: string;
  title: string;
  url: string;
  props?: ButtonProps;
}

const links: { anonymous: LinkType[]; authenticated: LinkType[] } = {
  anonymous: [
    {
      title: 'Features',
      url: '/#features'
    },
    {
      title: 'Pricing',
      url: '/#pricing'
    },
    {
      title: 'Sign Up',
      url: '/sign-up/',
      props: {
        variant: 'solid',
        bgColor: 'brand.500',
        color: 'gray.50',
        _hover: {
          bgColor: 'brand.600'
        }
      }
    },
    {
      title: 'Sign in',
      url: '/sign-in/'
    }
  ],
  authenticated: [
    {
      title: 'Features',
      url: '/#features'
    },
    {
      title: 'Pricing',
      url: '/#pricing'
    },
    {
      title: 'Sign out',
      url: '/sign-out/',
      props: {
        variant: 'solid',
        bgColor: 'brand.400',
        color: 'black',
        _hover: {
          bgColor: 'brand.500'
        }
      }
    }
  ]
};

const DesktopNavContent = (props: BoxProps) => {
  const { isAuthenticated, currentOrganisation, isLoading } = useAuthState();

  let linkSet = links.anonymous.map((link) => (
    <Button as={NextLink} href={link.url} key={link.title} {...link.props}>
      {link.title}
    </Button>
  ));

  if (!isLoading && isAuthenticated) {
    const dashLink = (
      <Button
        as={NextLink}
        href={{
          pathname: `/dashboard/${currentOrganisation?.id || ''}`
        }}
      >
        Dashboard
      </Button>
    );
    linkSet = [
      dashLink,
      ...links.authenticated.map((link) => (
        <NextLink href={link.url} key={link.title}>
          <Button {...link.props}>{link.title}</Button>
        </NextLink>
      ))
    ];
  }

  return (
    <Box as='nav' {...props}>
      {isLoading && null}
      <Flex justify='space-between'>
        <Spacer />
        <HStack spacing={6} mr={4}>
          <ButtonGroup variant='ghost' spacing={2}>
            {isAuthenticated && linkSet}
            {!isAuthenticated && !isLoading && linkSet}
          </ButtonGroup>
          <DarkModeSwitch />
        </HStack>
      </Flex>
    </Box>
  );
};
const MobileNavContent = () => {
  const { isAuthenticated } = useAuthState();

  return (
    <>
      {/* Anonymous */}
      {!isAuthenticated &&
        links.anonymous.map((link) => (
          <Button as={NextLink} href={link.url} key={link.title} size='lg' variant='ghost' {...link.props}>
            {link.title}
          </Button>
        ))}

      {/* Authenticated */}
      {isAuthenticated &&
        links.authenticated.map((link) => (
          <Button as={NextLink} href={link.url} key={link.title} size='lg' variant='ghost' {...link.props}>
            {link.title}
          </Button>
        ))}
    </>
  );
};
export const NavContent = {
  Mobile: MobileNavContent,
  Desktop: DesktopNavContent
};
