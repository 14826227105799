import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { FaLinkedin } from 'react-icons/fa';
import { GiAnvil } from 'react-icons/gi';
import NextLink from 'next/link';
import { LinkType } from './navBar/NavContent';

const links: { product: LinkType[]; resources: LinkType[]; getStarted: LinkType[] } = {
  product: [
    {
      title: 'Features',
      url: '/#features'
    },
    {
      title: 'How it works',
      url: '/#how-it-works'
    },
    {
      title: 'Pricing',
      url: '/#pricing'
    }
  ],
  resources: [
    {
      title: 'About us',
      url: 'https://simplyanvil.com'
    },
    {
      title: 'Articles',
      url: '/posts/'
    },
    {
      title: 'Support',
      url: 'mailto:support@smartparse.io'
    },
    {
      title: 'Terms of service',
      url: 'https://app.getterms.io/view/akqbv/tos/en-au'
    },
    {
      title: 'Privacy policy',
      url: 'https://app.getterms.io/view/akqbv/privacy/en-au'
    },
    {
      title: 'Acceptable use',
      url: 'https://app.getterms.io/view/akqbv/aup/en-au'
    }
  ],
  getStarted: [
    {
      title: 'Usage calculator',
      url: '/#calculator'
    },
    {
      title: 'Sign in',
      url: '/sign-in/'
    },
    {
      title: 'Sign up',
      url: '/sign-up/'
    }
  ]
};

export const Footer = React.memo(() => {
  return (
    <Box color='on-accent' px={4}>
      <Stack
        spacing='8'
        direction={{ base: 'column', md: 'row' }}
        justify='space-between'
        py={{ base: '12', md: '16' }}
      >
        <Stack spacing={{ base: '6', md: '8' }} align='start'>
          <HStack spacing={0}>
            <Image src='/images/logo.webp' alt='SmartParse Logo' w='90px' height='90px' mr={4} />

            <VStack align='start'>
              <HStack spacing={0}>
                <Text fontWeight='bold' fontSize='2xl' color='whiteAlpha.500'>
                  Smart
                </Text>
                <Text fontWeight='bold' fontSize='2xl' color='whiteAlpha.700'>
                  Parse
                </Text>
              </HStack>
              <Heading as='h3' size='xs' fontWeight='extrabold' fontSize='sm' color='whiteAlpha.700'>
                A division of Simply Anvil
              </Heading>
            </VStack>
          </HStack>
        </Stack>
        <Stack direction={{ base: 'column-reverse', sm: 'column', lg: 'row' }} spacing={{ base: '12', md: '8' }}>
          <Stack
            direction={{ base: 'column-reverse', sm: 'row' }}
            spacing='8'
            mx={{ base: 'auto', lg: '' }}
            wrap='wrap'
          >
            <Stack spacing='4' minW='36' flex='1'>
              <Text fontSize='sm' fontWeight='bold' color={useColorModeValue('whiteAlpha.600', 'whiteAlpha.600')}>
                Product
              </Text>
              <Stack spacing='3' shouldWrapChildren>
                {links.product.map((link) => (
                  <Button
                    as={NextLink}
                    href={link.url}
                    key={link.title}
                    variant='link'
                    {...link.props}
                    color={useColorModeValue('brand.100', 'whiteAlpha.900')}
                  >
                    {link.title}
                  </Button>
                ))}
              </Stack>
            </Stack>
            <Stack spacing='4' minW='36' flex='1'>
              <Text fontSize='sm' fontWeight='bold' color={useColorModeValue('whiteAlpha.600', 'whiteAlpha.600')}>
                Resources
              </Text>
              <Stack spacing='3' shouldWrapChildren>
                {links.resources.map((link) => (
                  <Button
                    as={NextLink}
                    href={link.url}
                    prefetch={false}
                    key={link.title}
                    variant='link'
                    {...link.props}
                    color={useColorModeValue('brand.100', 'whiteAlpha.900')}
                  >
                    {link.title}
                  </Button>
                ))}
              </Stack>
            </Stack>
            <Stack spacing='4' minW='36' flex='1'>
              <Text fontSize='sm' fontWeight='bold' color={useColorModeValue('whiteAlpha.600', 'whiteAlpha.600')}>
                Getting started
              </Text>
              <Stack spacing='3' shouldWrapChildren>
                {links.getStarted.map((link) => (
                  <Button
                    as={NextLink}
                    href={link.url}
                    prefetch={false}
                    key={link.title}
                    variant='link'
                    {...link.props}
                    color={useColorModeValue('brand.100', 'whiteAlpha.900')}
                  >
                    {link.title}
                  </Button>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {/* <Divider borderColor='bg-accent-subtle'/> */}
      <Stack
        pt='8'
        pb='4'
        justify='space-between'
        direction={{ base: 'column-reverse', md: 'row' }}
        align='center'
        color={useColorModeValue('whiteAlpha.900', 'whiteAlpha.700')}
      >
        <Text fontSize='sm' color='on-accent-subtle'>
          &copy;
          {`${new Date().getFullYear()} `}
          Simply Anvil (Pty) Ltd All rights reserved.
        </Text>

        <ButtonGroup variant='ghost-on-accent'>
          <IconButton
            as='a'
            href='https://www.linkedin.com/company/simplyanvil'
            aria-label='LinkedIn'
            icon={<FaLinkedin fontSize='1.25rem' />}
          />
          <IconButton
            as='a'
            href='https://simplyanvil.com'
            aria-label='Simply Anvil'
            icon={<GiAnvil fontSize='1.25rem' />}
          />
          {/* <IconButton as='a' href='' aria-label='Twitter' icon={<FaTwitter fontSize='1.25rem' />} /> */}
        </ButtonGroup>
      </Stack>
    </Box>
  );
});

Footer.displayName = 'Footer';
