import React from 'react';
import { IconButton, useColorMode } from '@chakra-ui/react';
import { FiMoon, FiSun } from 'react-icons/fi';

export function DarkModeSwitch() {
  const { colorMode, toggleColorMode } = useColorMode();
  // const isDark = colorMode === 'dark';
  return (
    <IconButton
      icon={colorMode === 'light' ? <FiMoon fontSize='1.25rem' /> : <FiSun fontSize='1.25rem' />}
      onClick={toggleColorMode}
      aria-label='Toggle color mode'
      variant='ghost'
    />
  );
}
